import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useHistory
} from "react-router-dom";

import ScrollToTop from 'react-router-scroll-top';

/*
import Modal from 'react-modal-dialog';
*/


class App extends React.Component {
    render() {
        return <div>
            <Switch>
                <Route exact path="/">
                    <Home/>
                </Route>
                <Route exact path="/ok1">
                    <ProjectDetails name="ok1" heading="Family House / Vodice, Croatia"/>
                </Route>
                <Route exact path="/ok0">
                    <ProjectDetails name="ok0" heading="Residential Complex / Primošten, Croatia"/>
                </Route>
                <Route exact path="/ok2">
                        <ProjectDetails name="ok2" heading="Residental Building / Tribunj, Croatia"/>
                    </Route>
                    <Route exact path="/ok3">
                        <ProjectDetails name="ok3" heading="Family House / Šibenik, Croatia" />
                    </Route>
                    <Route exact path="/ok4">
                        <ProjectDetails name="ok4" heading="Apartment Building / Bilice, Croatia" />
                    </Route>
                    <Route exact path="/ok5">
                        <ProjectDetails name="ok5" heading="Residential Complex / Marina, Croatia" />
                    </Route>
                    <Route exact path="/ok6">
                        <ProjectDetails name="ok6" heading="Family House / Vodice, Croatia" />
                    </Route>
                    <Route exact path="/ok7">
                        <ProjectDetails name="ok7" heading="Mixed Used Architecure, Commercial, Housing / Šibenik, Croatia" />
                    </Route>
                    <Route exact path="/ok8">
                        <ProjectDetails name="ok8" heading="Guest House / Zablaće, Croatia" />
                    </Route>
                    <Route exact path="/ok9">
                        <ProjectDetails name="ok9" heading="Housing / Vodice, Croatia" />
                    </Route>
                    <Route exact path="/ok10">
                        <ProjectDetails name="ok10" heading="Palace Divnić, Project / Šibenik, Croatia" />
                    </Route>
                    <Route exact path="/ok11">
                        <ProjectDetails name="ok11" heading="King Krešimir Hotel, Renovation / Šibenik, Croatia" />
                    </Route>
            </Switch>
        </div>;
    }
}

class Contact extends React.Component {

    render() {
        return <div className='popup'>
            <div className='popup_inner'>
                <div className="contactContent">
                    <div className="logo"></div>
                    <div className="marginTop4">TRIVA ARCHITECTS d.o.o.</div>
                    <div className="marginTop1">Poljana 3, 22000 Šibenik</div>
                    <div className="marginTop1">+385 98 985 2249</div>
                    <div className="marginTop1">
                        <a href="mailto:triva.arch@gmail.com">triva.arch@gmail.com</a>
                    </div>
                    <div className="marginTop1">
                        <a href="https://www.facebook.com/profile.php?id=725461491125057&ref=br_rs">trivaarchitects</a>
                    </div>
                    <div className="marginTop6">
                        <button className="buttonSize" onClick = {this.props.handler}>Close</button>
                    </div>
                    <div className="development">Development: <br/>Monika Bursać</div>
                </div>
            </div>
        </div>
    }
}

class ProjectDetails extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <div>
            <h1>{this.props.heading}</h1>
            <img className={this.props.name + " marginTop"} alt={this.props.name}/>
            <img className={this.props.name + "_2 marginTop"} alt={this.props.name + "_2"}/>
        </div>
    }
}

function Projects() {
    const history = useHistory();
    const imageList = ["ok1", "ok0", "ok2", "ok3", "ok4", "ok5", "ok6", "ok7", "ok8", "ok9", "ok10", "ok11"];

    function handleClick(imageName) {
        history.push("/" + imageName);
    }

    return (
        imageList.map(x => {
            return (<img key={x} className={x + " marginTop"} alt={x} onClick={b => handleClick(x)}/>)
        })

    )
}

class Home extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            contact: false
        }
    }

    toggleContact = () => {
        this.setState((state, props) => ({
            contact: !state.contact
        }));
    };

    render() {
        return <div>
            <div className="homeWrapper marginTop">
                <div className="menu">
                    <p className="sentence sentenceMargin">TA is an architecture, design and urbanism office which engages
                        mediterranean in providing
                        solutions <br/> to contemporary architectural and urban issues.</p>
                    <div className="mainLogoProps"><div className="details maxWidthMainLogo" onClick={() => this.toggleContact()}></div>
                    </div>
                </div>
            </div>
            {this.state.contact ? <Contact handler = {this.toggleContact}/> : null}
            <Projects/>
           <div className="marginTop">© 2020. All rights reserved.</div>
        </div>;
    }
}


ReactDOM.render(<Router>
    <ScrollToTop>
        <App/>
    </ScrollToTop>
</Router>, document.getElementById("root"));


